<script setup lang="ts">
  import { useBreakpoint } from "@/lib/useBreakpoint"
  import { computed } from "vue"
  import { Button } from "@/components/ui/button"

  const emits = defineEmits(["actionButtonClick", "closeButtonClick"])

  const props = defineProps<{
    title: string
    content: string
    label: string
    illustration?: string
    showCloseButton: boolean
  }>()

  const isTablet = useBreakpoint("sm")
  const onCloseButtonClick = () => {
    emits("closeButtonClick")
  }
  const onActionButtonClick = () => {
    emits("actionButtonClick")
  }
  const imageUrl = computed(
    () =>
      new URL(
        `../../../assets/illustrations/${props.illustration}.svg`,
        import.meta.url,
      ).href,
  )
</script>

<template>
  <div
    class="flex flex-wrap justify-between rounded border border-primary-300 bg-secondary-500 p-4 sm:p-8"
  >
    <p
      class="h3 mb-1 shrink-0 grow-0 basis-full text-secondary-100 sm:hidden"
      data-testid="banner-title"
    >
      {{ props.title }}
    </p>
    <div class="flex-1">
      <p
        data-testid="banner-title-desktop"
        class="h1 hidden text-secondary-100 sm:mb-2 sm:block"
      >
        {{ props.title }}
      </p>
      <p
        data-testid="banner-content"
        class="text-md mb-4 leading-6 text-secondary-100 sm:mb-6 sm:text-base sm:leading-7"
      >
        {{ props.content }}
      </p>
      <Button
        data-testid="banner-cta-btn"
        :size="isTablet ? 'lg' : 'sm'"
        @click="onActionButtonClick"
        >{{ props.label }}</Button
      >
      <Button
        v-if="showCloseButton"
        data-testid="banner-close-btn"
        variant="navSubItem"
        class="ml-3 text-secondary-100 sm:ml-6"
        :size="isTablet ? 'lg' : 'sm'"
        @click="onCloseButtonClick"
        >Close</Button
      >
    </div>
    <div>
      <img
        data-testid="banner-illustration"
        class="ml-2 h-[92px] max-w-fit sm:ml-12 sm:h-[140px]"
        :src="imageUrl"
        alt=""
      />
    </div>
  </div>
</template>
