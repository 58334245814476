<script setup lang="ts">
  import { computed } from "vue"
  import { useRoute } from "vue-router"
  import { PageHeader } from "@/components/ui/page-header"

  import { Sidebar } from "@/views/sidebar"

  const route = useRoute()
  const title = computed(() => (route.meta?.title as string) || "")
</script>

<template>
  <main
    class="container flex flex-col px-6 py-4 sm:px-10 md:flex-row md:flex-nowrap md:px-14"
  >
    <PageHeader
      v-if="title"
      class="mb-4 mt-2 block md:my-6 md:hidden"
      :title="title"
    />
    <Sidebar />
    <div class="flex-1">
      <PageHeader
        v-if="title"
        class="hidden sm:mb-6 md:mt-5 md:block"
        :title="title"
      />
      <slot />
    </div>
  </main>
</template>
