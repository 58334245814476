<script lang="ts" setup>
  import { cn } from "@/lib/utils"
  import {
    CalendarHeader,
    useForwardProps,
    type CalendarHeaderProps,
  } from "radix-vue"
  import { computed, type HTMLAttributes } from "vue"

  const props = defineProps<
    CalendarHeaderProps & { class?: HTMLAttributes["class"] }
  >()

  const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props

    return delegated
  })

  const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
  <CalendarHeader
    :class="
      cn('relative flex w-full items-center justify-between', props.class)
    "
    v-bind="forwardedProps"
  >
    <slot />
  </CalendarHeader>
</template>
