<script setup lang="ts">
  import { useAddressesByPostcode } from "@/api/address/address"
  import { AddressMeterPointDetails } from "@/api/model"
  import { toTypedSchema } from "@vee-validate/zod"
  import { useVModel } from "@vueuse/core"
  import { useForm } from "vee-validate"
  import { Ref, ref } from "vue"
  import { z } from "zod"
  import Button from "@/components/ui/button/Button.vue"
  import TextInput from "@/components/ui/inputs/text-input/TextInput.vue"

  const props = withDefaults(
    defineProps<{
      modelValue?: string
      defaultValue?: string
      placeholder?: string
    }>(),
    { defaultValue: "" },
  )

  const emits = defineEmits<{
    (e: "update:modelValue", payload: string): void
    (e: "valid-postcode", payload: string): void
    (e: "addresses", payload: AddressMeterPointDetails[]): void
    (e: "query-error", payload: boolean): void
  }>()

  const modelValue = useVModel(props, "modelValue", emits, {
    passive: true,
    defaultValue: props.defaultValue,
  }) as Ref<string>

  const addressQuery = useAddressesByPostcode(modelValue, {
    query: { enabled: false },
  })
  const isLoading = ref(false)
  const postcodeRegEx = /^[a-z]{1,2}\d[a-z\d]?\+*\s*\d[a-z]{2}$/i
  const formSchema = toTypedSchema(
    z.object({
      postcode: z
        .string({
          required_error: "Please enter a UK postcode to search",
        })
        .min(1, "Please enter a UK postcode to search")
        .regex(postcodeRegEx, "Enter a valid postcode (e.g., AB12 3CD)"),
    }),
  )

  const { handleSubmit, validate } = useForm({
    validationSchema: formSchema,
  })

  const validateSubmitPostcode = handleSubmit(async () => {
    modelValue.value = modelValue.value
      .replace(/^(.*[^\s])\s*(\d)/, "$1 $2")
      .toUpperCase()
    isLoading.value = true
    await addressQuery.refetch()

    emits("query-error", !!addressQuery.error.value)
    emits("valid-postcode", modelValue.value)
    emits("addresses", addressQuery.data?.value || [])
    isLoading.value = false
  })

  defineExpose({ validate, validateSubmitPostcode })
</script>

<template>
  <div class="leading-3">
    <TextInput
      v-model="modelValue"
      label="Postcode"
      name="postcode"
      :placeholder="placeholder"
      class="mb-4"
    />

    <Button :loading="isLoading" size="sm" @click="validateSubmitPostcode"
      >Find Address</Button
    >
  </div>
</template>
