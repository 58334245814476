import { useGetAccountId } from "@/lib/useGetAccountId"
import { computed, ref } from "vue"

const isModalOpen = ref(false)

export function useSwitchAccountModal() {
  const accountIdQuery = useGetAccountId()

  const hasMultipleAccounts = computed(
    () => (accountIdQuery.data.value?.accountIDs || []).length > 1,
  )

  const openModal = () => {
    isModalOpen.value = true
  }

  const closeModal = () => {
    isModalOpen.value = false
  }

  return {
    isModalOpen,
    hasMultipleAccounts,
    openModal,
    closeModal,
  }
}
