<script setup lang="ts">
  import { getInputAriaDescribedBy } from "@/lib/inputComponentUtils"
  import { cn } from "@/lib/utils"
  import { ComboboxItem, SupportingLabel } from "@/types/InputComponentTypes"
  import { useVModel } from "@vueuse/core"
  import { PopoverAnchor } from "radix-vue"
  import { computed, HTMLAttributes, nextTick, ref, watch } from "vue"
  import {
    Command,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
  } from "@/components/ui/command"
  import {
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage,
  } from "@/components/ui/form"
  import FormSupportingLabel from "@/components/ui/form/FormSupportingLabel.vue"
  import { Popover, PopoverContent } from "@/components/ui/popover"

  const props = withDefaults(
    defineProps<{
      class?: HTMLAttributes["class"]
      defaultValue?: ComboboxItem
      modelValue?: ComboboxItem
      items?: ComboboxItem[]
      label: string
      showLabel?: boolean
      extraLabel?: string
      supportingLabels?: SupportingLabel[]
      name: string
      autocomplete?: string
      autofocus?: boolean
      disabled?: boolean
      required?: boolean
      placeholder?: string
      keepSupportingLabelsOnError?: boolean
    }>(),
    {
      items: () => [],
      autocomplete: "none",
      showLabel: true,
    },
  )

  const emits = defineEmits<{
    (e: "update:modelValue", payload: ComboboxItem): void
    (e: "itemSelected"): void
  }>()

  const modelValue = useVModel(props, "modelValue", emits, {
    passive: true,
    defaultValue: props.defaultValue,
  })

  watch(modelValue, (newValue) => {
    if (!newValue) {
      value.value = undefined
    }
  })

  const comboboxItems = computed(() =>
    props.items.map((item) => {
      if (typeof item === "string") return { label: item, value: item }

      return item
    }),
  )

  const onItemSelected = (index: number) => {
    modelValue.value = comboboxItems.value[index]
    emits("itemSelected")
  }

  const initialValue =
    typeof modelValue.value === "object"
      ? modelValue.value.label
      : modelValue.value

  const value = ref(initialValue)
  const isComboboxOpen = ref(false)

  const getAriaDescribedBy = (
    formDescriptionId: string,
    formMessageId: string,
    error: string,
  ) =>
    getInputAriaDescribedBy(
      {
        supportingLabels: props.supportingLabels,
        extraLabel: props.extraLabel,
        error,
      },
      formDescriptionId,
      formMessageId,
    )

  const selectedValue = ref()

  const clearSelectedValue = async () => {
    await nextTick()
    await nextTick()
    await nextTick()
    await nextTick()
    selectedValue.value = null
  }
</script>

<template>
  <FormField
    v-model="modelValue"
    :name="name"
    :validate-on-blur="false"
    :validate-on-change="false"
    :validate-on-model-update="false"
  >
    <Command
      v-model="value"
      v-model:open="isComboboxOpen"
      v-model:selected-value="selectedValue"
      class="relative"
      :class="cn(props.class)"
      @keydown.enter.prevent
      @keyup.space.prevent
      @update:search-term="clearSelectedValue"
    >
      <template #default="{ comboboxOpen }">
        <Popover :open="comboboxOpen">
          <FormItem v-bind="$attrs">
            <FormControl
              v-slot="{
                error,
                formItemId,
                ariaInvalid,
                formDescriptionId,
                formMessageId,
              }"
            >
              <div
                v-if="showLabel || extraLabel"
                class="flex items-baseline justify-between"
              >
                <FormLabel v-if="label && showLabel" data-testid="form-label">{{
                  label
                }}</FormLabel>
                <span
                  v-if="extraLabel"
                  :id="formDescriptionId"
                  class="label-text-strong text-secondary-500 dark:text-secondary-300"
                  data-testid="form-extra-label"
                  :aria-label="`${extraLabel}.`"
                >
                  {{ extraLabel }}
                </span>
              </div>
              <PopoverAnchor @click="clearSelectedValue">
                <CommandInput
                  :id="formItemId"
                  :aria-invalid="ariaInvalid"
                  :placeholder="placeholder"
                  :error="error"
                  :disabled="disabled"
                  :autofocus="autofocus"
                  :autocomplete="autocomplete"
                  :aria-label="label"
                  :aria-describedby="
                    getAriaDescribedBy(formDescriptionId, formMessageId, error)
                  "
                />
              </PopoverAnchor>
              <template
                v-if="
                  supportingLabels?.length &&
                  (keepSupportingLabelsOnError || !error)
                "
              >
                <FormSupportingLabel
                  v-for="(supportingLabel, index) in supportingLabels"
                  :id="`${formMessageId}-${index}`"
                  :key="`${formMessageId}-${index}`"
                  :supporting-label="supportingLabel"
                  :data-testid="`form-supporting-label-${index}`"
                >
                </FormSupportingLabel>
              </template>
              <FormMessage />
            </FormControl>
          </FormItem>
          <PopoverContent
            :class="
              cn(
                'w-[var(--radix-popper-anchor-width)] rounded-sm border-none p-0',
              )
            "
            :side-offset="8"
          >
            <CommandList>
              <CommandEmpty>No items found.</CommandEmpty>
              <CommandGroup>
                <CommandItem
                  v-for="(item, index) in comboboxItems"
                  :key="index"
                  :value="item.label"
                  :disabled="item.disabled"
                  :data-testid="`combobox-item-${index}`"
                  @select="onItemSelected(index)"
                >
                  {{ item.label }}
                </CommandItem>
              </CommandGroup>
            </CommandList>
          </PopoverContent>
        </Popover>
      </template>
    </Command>
  </FormField>
</template>
