<script lang="ts" setup>
  import { cn } from "@/lib/utils"
  import {
    CalendarHeadCell,
    useForwardProps,
    type CalendarHeadCellProps,
  } from "radix-vue"
  import { computed, type HTMLAttributes } from "vue"

  const props = defineProps<
    CalendarHeadCellProps & { class?: HTMLAttributes["class"] }
  >()

  const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props

    return delegated
  })

  const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
  <CalendarHeadCell
    :class="
      cn('label-text flex-1 p-0 font-normal text-secondary-400', props.class)
    "
    v-bind="forwardedProps"
  >
    <slot />
  </CalendarHeadCell>
</template>
