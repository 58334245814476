<script setup lang="ts">
  import { useSubmitChangeOfTenancyMoveIn } from "@/api/change-of-tenancy/change-of-tenancy"
  import { Address } from "@/api/model"
  import { DefaultDateFormat } from "@/constants/date"
  import { ExternalRoute } from "@/constants/externalRoute"
  import { Route } from "@/constants/route"
  import { formatDate } from "@/lib/dateHelper"
  import { useCotMoveIn } from "@/lib/useCotMoveIn"
  import { useStepWizard } from "@/lib/useStepWizard"
  import TrackingService from "@/services/TrackingService"
  import dayjs from "dayjs"
  import advancedFormat from "dayjs/plugin/advancedFormat"
  import customParseFormat from "dayjs/plugin/customParseFormat"
  import { computed } from "vue"
  import { useRouter } from "vue-router"
  import { Alert } from "@/components/ui/alert"
  import { Button } from "@/components/ui/button"
  import { Link } from "@/components/ui/link"
  import { StepWizardProgressCard } from "@/components/ui/step-wizard"

  type StepInformation = {
    title: string
    editStep?: number
    content: {
      title: string
      text: string
    }[]
  }

  dayjs.extend(customParseFormat)
  dayjs.extend(advancedFormat)

  const { goToStep } = useStepWizard()
  const { state } = useCotMoveIn()

  const fdor = computed(
    () =>
      state.value.fdor &&
      formatDate(dayjs(state.value.fdor, DefaultDateFormat)),
  )

  const information = computed<StepInformation[]>(() => [
    {
      title: "New Address",
      editStep: 0,
      content: [
        {
          title: "Address",
          text: state.value.moveInAddress?.fullAddress || "",
        },
      ],
    },
    {
      title: "Moving in Date",
      editStep: 1,
      content: [
        {
          title: "First date of responsibility",
          text: fdor.value,
        },
      ],
    },
    {
      title: "New Occupant Information",
      editStep: 2,
      content: [
        {
          title: "Name",
          text: `${state.value.firstName} ${state.value.lastName}`,
        },
        { title: "Date of birth", text: state.value.dateOfBirth },
        { title: "Email", text: state.value.email },
        { title: "Phone", text: state.value.phone },
      ],
    },
  ])

  function getMoveInTypeformLink(): string {
    TrackingService.sendEvent("move-in-typeform-redirect")
    return ExternalRoute.movingInTypeform
  }

  const moveIn = useSubmitChangeOfTenancyMoveIn()
  const router = useRouter()
  const submitMoveIn = async () => {
    await moveIn.mutateAsync({
      data: {
        billing_account_id: state.value.moveInAddress
          ?.billingAccountId as number,
        first_date_of_responsibility: dayjs(state.value.fdor).format(
          "YYYY-MM-DD",
        ),
        new_occupant_contact: {
          email: state.value.email,
          date_of_birth: dayjs(state.value.dateOfBirth).format("YYYY-MM-DD"),
          first_name: state.value.firstName,
          last_name: state.value.lastName,
          marketing_opt_in: state.value.rewardsAndUpdates,
          phone1: state.value.phone,
        },
        supply_address: state.value.moveInAddress?.address as Address,
      },
    })
    if (!moveIn.error.value) {
      router.push(Route.cotMoveInComplete)
    }
  }
</script>

<template>
  <StepWizardProgressCard title="Review information">
    <div class="space-y-8">
      <div
        v-for="(stepInformation, index) in information"
        :key="`${stepInformation.title}_${index}`"
        class="flex flex-col gap-3"
      >
        <div class="flex justify-between gap-2">
          <h4>{{ stepInformation.title }}</h4>
          <button
            v-if="stepInformation.editStep != null"
            class="link"
            @click="goToStep(stepInformation.editStep)"
          >
            Edit
          </button>
        </div>
        <div
          v-for="(stepContent, contentIndex) in stepInformation.content"
          :key="`${stepContent.title}_${contentIndex}`"
        >
          <p class="body-text-strong" data-testid="step-content_title">
            {{ stepContent.title }}
          </p>
          <p class="label-text text-secondary-500">
            {{ stepContent.text }}
          </p>
        </div>
      </div>
    </div>
    <Alert
      v-if="moveIn.isError.value"
      variant="error"
      class="mt-4 w-auto text-left text-sm sm:text-base"
    >
      We encountered an issue while processing your move-in request. Please take
      a moment to
      <Link class="link" target="_blank" :to="getMoveInTypeformLink()"
        >complete this form</Link
      >, and we'll take care of the rest.</Alert
    >
    <template #footer>
      <Button
        variant="tonal"
        size="md"
        :loading="moveIn.isPending.value"
        @click="submitMoveIn"
        >Submit</Button
      >
    </template>
  </StepWizardProgressCard>
</template>
