import { Alert } from "@/api/model"

import { AlertModel } from "."
import { alertTemplates } from "./alertTemplates"

const getAlertModel = ({ name, severity }: Alert): AlertModel | undefined => {
  const item = alertTemplates.find((template) => template.name === name)
  if (!item) {
    return undefined
  }
  return { ...item, severity }
}

export const getAlertModels = (alerts: Alert[]) =>
  alerts.map(getAlertModel).filter(Boolean) as AlertModel[]
