import { AddressMeterPointDetails } from "@/api/model"
import { ref } from "vue"

interface MoveInState {
  moveInAddress?: AddressMeterPointDetails
  fdor: string
  firstName: string
  lastName: string
  phone: string
  email: string
  dateOfBirth: string
  rewardsAndUpdates: boolean
}

const state = ref<MoveInState>({
  moveInAddress: undefined,
  fdor: "",
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  dateOfBirth: "",
  rewardsAndUpdates: false,
})

export const useCotMoveIn = () => {
  const updateDetails = (data: Partial<MoveInState>) => {
    state.value = { ...state.value, ...data }
  }

  return {
    state,
    updateDetails,
  }
}
