<script setup lang="ts">
  import { Skeleton } from "@/components/ui/skeleton"

  const getSkeletonWidth = (index: number): string => {
    if (index % 3 === 0) return "w-[180px]"
    if (index % 2 === 0) return "w-[140px]"
    return "w-[100px]"
  }
</script>

<template>
  <ul class="space-y-2" data-testid="sidebar-items-skeleton">
    <li v-for="index in 8" :key="index">
      <Skeleton :class="['h-[36px]', getSkeletonWidth(index)]" />
    </li>
  </ul>
</template>
