<script setup lang="ts">
  import { useBreakpoint } from "@/lib/useBreakpoint.ts"
  import { useSwitchAccountModal } from "@/lib/useSwitchAccountModal"
  import { cn, preventBodyScroll } from "@/lib/utils.ts"
  import { NavbarItem } from "@/types/Navbar.ts"
  import { useColorMode } from "@vueuse/core"
  import { inject, ref, watch } from "vue"
  import { Button } from "@/components/ui/button"
  import Icon from "@/components/ui/icons/Icon.vue"

  import MobileItem from "@/views/navbar/items/mobile/MobileItem.vue"
  import NestedMobileItem from "@/views/navbar/items/mobile/NestedMobileItem.vue"
  import NavbarList from "@/views/navbar/NavbarList.vue"

  import logoImageDark from "@/assets/images/logo-dark.svg"
  import logoImageLight from "@/assets/images/logo-light.svg"

  const props = defineProps<{
    desktopNavigationItems: NavbarItem[]
    tabletNavigationItems: NavbarItem[]
    mobileNavigationItems: NavbarItem[]
    primaryActionLink?: NavbarItem
    secondaryActionLink?: NavbarItem
  }>()

  const mode = useColorMode()
  const { isModalOpen } = useSwitchAccountModal()
  const isNavbarVisible = ref(false)
  const homeLink = import.meta.env.VITE_PUBLIC_WEBSITE_URL

  const toggleNavbar = () => {
    isNavbarVisible.value = !isNavbarVisible.value

    // Prevents scrolling when the navbar is open
    preventBodyScroll(isNavbarVisible.value)
  }

  const isTabletScreen = useBreakpoint("sm")
  const isStaging = inject<boolean>("isStaging")

  watch(isTabletScreen, (isTabletScreenValue) => {
    if (isTabletScreenValue) {
      isNavbarVisible.value = false
    }
  })

  watch(isModalOpen, (isOpen) => {
    if (isOpen && isNavbarVisible.value) {
      toggleNavbar()
    }
  })
</script>

<template>
  <nav class="my-2 w-full">
    <div
      :class="
        cn(
          `relative z-50 mx-3 flex items-center justify-between rounded-full bg-secondary-100 px-2 shadow-sm dark:bg-secondary-600 sm:py-2`,
          isNavbarVisible && !isTabletScreen && 'shadow-none',
        )
      "
    >
      <a
        :href="homeLink"
        class="flex items-center overflow-visible sm:ml-2 sm:h-9 lg:h-7"
        data-testid="logo-link"
      >
        <img
          :src="mode === 'light' ? logoImageDark : logoImageLight"
          alt="So Energy logo"
        />
      </a>

      <div class="hidden gap-4 md:flex" data-testid="desktop-navbar">
        <NavbarList :items="props.desktopNavigationItems" />
        <slot />
      </div>

      <div
        class="hidden gap-4 sm:flex md:hidden md:py-1"
        data-testid="tablet-navbar"
      >
        <NavbarList :items="props.tabletNavigationItems" />
        <slot />
      </div>

      <div class="sm:hidden" data-testid="mobile-navbar">
        <Button
          :class="
            cn(
              `leading-base flex items-center gap-2 rounded-full text-sm`,
              isNavbarVisible && 'pr-3',
            )
          "
          size="sm"
          :variant="isNavbarVisible ? 'text' : 'outline'"
          data-testid="mobile-navbar-trigger"
          @click="toggleNavbar"
        >
          {{ isNavbarVisible ? "Close" : "Menu" }}
          <Icon v-if="isNavbarVisible" size="sm" name="x" class="ml-1" />
        </Button>
      </div>
    </div>
  </nav>

  <div
    :class="
      cn(
        'fixed inset-x-3 z-20 overflow-hidden rounded-sm',
        isNavbarVisible && 'inset-y-2',
        isStaging && 'top-18',
      )
    "
  >
    <transition
      enter-active-class="duration-300 ease-in-out"
      enter-from-class="transform -translate-y-full"
      enter-to-class="opacity-100"
      leave-active-class="duration-200 ease-in-out"
      leave-from-class="opacity-100"
      leave-to-class="transform -translate-y-full"
    >
      <div
        v-if="isNavbarVisible"
        class="flex h-full flex-col justify-between gap-4 bg-secondary-100 px-5 pb-6 pt-14 shadow-md dark:bg-secondary-600 sm:hidden"
        data-testid="mobile-navbar-content"
      >
        <ul class="overflow-auto pb-2">
          <template
            v-for="(
              { to, label, children, isDisabled }, index
            ) in props.mobileNavigationItems"
          >
            <MobileItem
              v-if="to"
              :key="index"
              :to="to"
              :label="label"
              :disabled="isDisabled"
              @click="toggleNavbar"
            />
            <NestedMobileItem
              v-if="children?.length"
              :key="index"
              :sub-items="children"
              :trigger-label="label"
              :toggle-navbar="toggleNavbar"
            />
          </template>
        </ul>
        <div class="flex flex-col gap-3">
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>
