<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import {
    ComboboxItem,
    useForwardPropsEmits,
    type ComboboxItemEmits,
    type ComboboxItemProps,
  } from "radix-vue"
  import { computed, type HTMLAttributes } from "vue"

  const props = defineProps<
    ComboboxItemProps & {
      class?: HTMLAttributes["class"]
      autocomplete?: string
    }
  >()
  const emits = defineEmits<ComboboxItemEmits>()

  const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props

    return delegated
  })

  const forwarded = useForwardPropsEmits(delegatedProps, emits)
</script>

<template>
  <ComboboxItem
    v-bind="forwarded"
    :autocomplete="autocomplete"
    :class="
      cn(
        'relative mb-2 flex cursor-default select-none items-center rounded-full border border-transparent px-4 py-1 text-sm outline-none last:mb-0',
        'data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
        'hover:bg-secondary-300 hover:data-[state=checked]:bg-secondary-700',
        'ring-primary-400 data-[highlighted]:border-primary-400 data-[highlighted]:outline-none data-[highlighted]:ring-1 data-[highlighted]:ring-offset-0',
        'data-[state=checked]:bg-secondary-700 data-[state=checked]:text-secondary-100',
        'dark:hover:bg-secondary-600 dark:data-[state=checked]:bg-secondary-100 dark:data-[state=checked]:text-secondary-700',
        props.class,
      )
    "
    @pointermove.prevent
  >
    <slot />
  </ComboboxItem>
</template>
