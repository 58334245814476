export function nexusHomePageEnabled(): boolean {
  const currentUrl = window.location.href

  if (import.meta.env.VITE_USE_NEXUS_HOMEPAGE === "false") {
    return false
  }
  const isFeatureFlagEnabled =
    import.meta.env.VITE_USE_NEXUS_HOMEPAGE === "true"
  const isNexusPreviewUrl =
    /^https:\/\/fe-nexus(?:-.+)?\.(?:sandbox|staging|preview)\.soenergy\.co/.test(
      currentUrl,
    )

  return isFeatureFlagEnabled || isNexusPreviewUrl
}
