<script setup lang="ts">
  import { computed } from "vue"
  import { useRoute } from "vue-router"
  import { PageHeader } from "@/components/ui/page-header"

  const route = useRoute()
  const title = computed(() => (route.meta?.title as string) || "")
</script>

<template>
  <main class="container px-6 py-6">
    <PageHeader
      v-if="title"
      class="hidden sm:mb-6 md:mt-5 md:block"
      :title="title"
    />
    <slot />
  </main>
</template>
