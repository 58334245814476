import { cva, VariantProps } from "class-variance-authority"

export { default as Skeleton } from "./Skeleton.vue"

const skeletonConfig = {
  variants: {
    variant: {
      square: "rounded-md",
      rounded: "rounded-full",
    },
  },
}

export const skeletonVariants = cva("animate-pulse bg-skeleton", skeletonConfig)

export type SkeletonVariants = VariantProps<typeof skeletonVariants>

export const skeletonVariantKeys = Object.keys(
  skeletonConfig.variants.variant,
) as Array<keyof typeof skeletonConfig.variants.variant>
