<script lang="ts" setup>
  import {
    CalendarPrev,
    useForwardProps,
    type CalendarPrevProps,
  } from "radix-vue"
  import { computed, type HTMLAttributes } from "vue"
  import { IconButton } from "@/components/ui/icon-button"

  const props = defineProps<
    CalendarPrevProps & { class?: HTMLAttributes["class"] }
  >()

  const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props

    return delegated
  })

  const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
  <CalendarPrev v-bind="forwardedProps" as-child>
    <slot>
      <IconButton
        variant="text"
        icon="ArrowLeft"
        label="Close alert"
        size="sm"
      />
    </slot>
  </CalendarPrev>
</template>
