import { isAbsoluteUrl } from "./isAbsoluteUrl"

export const getPublicWebsiteUrl = (): string => {
  const publicWebsiteUrl = import.meta.env.VITE_PUBLIC_WEBSITE_URL
  return `${publicWebsiteUrl}/`
}
export const getMyAccountUrl = (): string => {
  const currentUrl = window.location.href
  const isLegacyMyAccountPreviewUrl =
    /^https:\/\/fe-my-account(?:-.+)?\.preview\.soenergy\.co/.test(currentUrl)

  if (isLegacyMyAccountPreviewUrl) {
    return "/myaccount/"
  }

  return `${getPublicWebsiteUrl()}myaccount/`
}

const pathToWebsiteUrl = (url: string) => {
  if (url.startsWith("/myaccount")) {
    return getMyAccountUrl() + url
  }

  return getPublicWebsiteUrl() + url
}

export const ensureResolvedWebsiteUrl = (url: string) => {
  if (isAbsoluteUrl(url)) {
    return url
  }
  return pathToWebsiteUrl(url)
}
