import useAuthErrorDialog from "@/lib/useAuthErrorDialog"
import { AxiosError, AxiosRequestConfig } from "axios"

const handleAuthErrorInterceptor = async (error: AxiosError) => {
  const originalRequest = error.config as AxiosRequestConfig
  const isAuthTokenRequired = !originalRequest.url?.startsWith("/api/v1/auth")
  if (
    error.response &&
    error?.response?.status === 403 &&
    isAuthTokenRequired
  ) {
    useAuthErrorDialog().showDialog()
  }

  return Promise.reject(error)
}

export default handleAuthErrorInterceptor
