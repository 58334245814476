<script setup lang="ts">
  import { cn } from "@/lib/utils.ts"
  import type { HTMLAttributes } from "vue"

  const props = defineProps<{
    src: string
    alt: string
    class?: HTMLAttributes["class"]
  }>()
</script>
<template>
  <img
    :src="props.src"
    :alt="props.alt"
    :class="cn('m-auto h-[160px] sm:h-[190px]', props.class)"
    :data-testid="`dialog-illustration-${props.alt}`"
  />
</template>
