import { StepWizard } from "@/types/StepWizard"
import { computed, onMounted, ref } from "vue"
import { useRouter } from "vue-router"

const modelValue = ref(0)
const steps = ref<StepWizard[]>([])
const isCloseDialogOpen = ref(false)

const isFirstStep = computed(() => modelValue.value === 0)
const isLastStep = computed(() => steps.value.length - 1 === modelValue.value)
const currentStep = computed(() => steps.value[modelValue.value])
const isRouteStep = computed(() => !!currentStep.value.route)
const currentComponent = computed(() => currentStep.value.component)
const progress = computed(
  () => ((modelValue.value + 1) * 100) / steps.value.length,
)

export const useInitStepWizard = (
  stepsAttr: StepWizard[],
  modelValueAttr: number = 0,
) => {
  modelValue.value = modelValueAttr
  steps.value = stepsAttr
  isCloseDialogOpen.value = false

  const router = useRouter()

  onMounted(() => {
    if (isRouteStep.value) {
      router.push(currentStep.value.route!)
    }
  })
}

export const useStepWizard = () => {
  const router = useRouter()

  const goBack = (): boolean => {
    if (isFirstStep.value) {
      return false
    }

    modelValue.value -= 1

    if (isRouteStep.value) {
      router.push(currentStep.value.route!)
    }

    return true
  }

  const goNext = (): boolean => {
    if (isLastStep.value) return false

    modelValue.value += 1

    if (isRouteStep.value) {
      router.push(currentStep.value.route!)
    }

    return true
  }

  const goToStep = (step: number) => {
    modelValue.value = step

    if (isRouteStep.value) {
      router.push(currentStep.value.route!)
    }
  }

  return {
    modelValue,
    steps,
    isFirstStep,
    isLastStep,
    currentStep,
    isRouteStep,
    currentComponent,
    progress,
    isCloseDialogOpen,
    goBack,
    goNext,
    goToStep,
  }
}
