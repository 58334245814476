<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import { HTMLAttributes, inject } from "vue"

  const props = defineProps<{ class?: HTMLAttributes["class"] }>()
  const hasIllustration = inject("hasIllustration")
</script>

<template>
  <div
    :class="
      cn(
        'mt-4 flex gap-3 ',
        hasIllustration
          ? 'justify-center sm:justify-center'
          : 'flex-col-reverse sm:flex-row sm:justify-end',
        props.class,
      )
    "
    data-testid="dialog-footer"
  >
    <slot />
  </div>
</template>
