/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Portal API Gateway
 * OpenAPI spec version: 2.0.0
 */
import {
  useMutation,
  type MutationFunction,
  type UseMutationOptions,
  type UseMutationReturnType,
} from "@tanstack/vue-query"
import { unref, type MaybeRef } from "vue"

import { customInstance, type ErrorType } from ".././client"
import type { ChangeOfTenancyMoveInRequest, Error } from ".././model"
import { useMutationOptions } from ".././mutationOptions"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * @summary Submit a request for change of tenancy move-in
 */
export const submitChangeOfTenancyMoveIn = (
  changeOfTenancyMoveInRequest: MaybeRef<ChangeOfTenancyMoveInRequest>,
  options?: SecondParameter<typeof customInstance>,
) => {
  changeOfTenancyMoveInRequest = unref(changeOfTenancyMoveInRequest)

  return customInstance<void>(
    {
      url: `/api/v1/change-of-tenancy/move-in`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: changeOfTenancyMoveInRequest,
    },
    options,
  )
}

export const useSubmitChangeOfTenancyMoveInMutationOptions = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof submitChangeOfTenancyMoveIn>>,
    TError,
    { data: ChangeOfTenancyMoveInRequest },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationOptions<
  Awaited<ReturnType<typeof submitChangeOfTenancyMoveIn>>,
  TError,
  { data: ChangeOfTenancyMoveInRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof submitChangeOfTenancyMoveIn>>,
    { data: ChangeOfTenancyMoveInRequest }
  > = (props) => {
    const { data } = props ?? {}

    return submitChangeOfTenancyMoveIn(data, requestOptions)
  }

  const customOptions = useMutationOptions({ ...mutationOptions, mutationFn })

  return customOptions
}

export type SubmitChangeOfTenancyMoveInMutationResult = NonNullable<
  Awaited<ReturnType<typeof submitChangeOfTenancyMoveIn>>
>
export type SubmitChangeOfTenancyMoveInMutationBody =
  ChangeOfTenancyMoveInRequest
export type SubmitChangeOfTenancyMoveInMutationError = ErrorType<Error>

/**
 * @summary Submit a request for change of tenancy move-in
 */
export const useSubmitChangeOfTenancyMoveIn = <
  TError = ErrorType<Error>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof submitChangeOfTenancyMoveIn>>,
    TError,
    { data: ChangeOfTenancyMoveInRequest },
    TContext
  >
  request?: SecondParameter<typeof customInstance>
}): UseMutationReturnType<
  Awaited<ReturnType<typeof submitChangeOfTenancyMoveIn>>,
  TError,
  { data: ChangeOfTenancyMoveInRequest },
  TContext
> => {
  const mutationOptions = useSubmitChangeOfTenancyMoveInMutationOptions(options)

  return useMutation(mutationOptions)
}
