import { AuthenticationResponse } from "@/api/model/authenticationResponse"
import { useNow } from "@vueuse/core"
import { useCookies } from "@vueuse/integrations/useCookies"
import { computed, ref } from "vue"

import { getApiRootDomain } from "./utils"

const AuthCookie = {
  longLivedSession: "myaccount_remember-me_long_lived_refresh_token",
  refreshTokenAvailable: "website_refresh_token_available",
}

const cookieOptions = {
  path: "/",
  domain: getApiRootDomain(),
}

const accessToken = ref<string | null>(null)
const refreshTokenExpiry = ref<Date | null>(null)
const accessTokenExpiry = ref<Date | null>(null)

export function useAuthSession() {
  const now = useNow()

  const isExpired = computed(() => {
    return (
      refreshTokenExpiry.value && now.value > new Date(refreshTokenExpiry.value)
    )
  })

  const isAuthenticated = computed(() => {
    return Boolean(
      accessTokenExpiry.value &&
        new Date() <= new Date(accessTokenExpiry.value),
    )
  })

  function hasRefreshTokenCookie() {
    return !!useCookies().get(AuthCookie.refreshTokenAvailable)
  }

  function isLongLived() {
    return useCookies().get(AuthCookie.longLivedSession) === "true"
  }

  function clearSessionData() {
    const cookies = useCookies()
    accessToken.value = null
    refreshTokenExpiry.value = null
    accessTokenExpiry.value = null

    cookies.remove(AuthCookie.longLivedSession, cookieOptions)
    cookies.remove(AuthCookie.refreshTokenAvailable, cookieOptions)
  }

  function setSessionData(
    data: AuthenticationResponse,
    isSessionExtended: boolean,
  ) {
    const cookies = useCookies()
    accessToken.value = data.accessToken
    accessTokenExpiry.value = new Date(data.accessTokenExpiry)
    refreshTokenExpiry.value = new Date(data.sessionExpiry)

    if (isSessionExtended) {
      cookies.set(AuthCookie.longLivedSession, true, {
        ...cookieOptions,
        expires: refreshTokenExpiry.value,
      })
    }
    cookies.set(AuthCookie.refreshTokenAvailable, true, {
      ...cookieOptions,
      expires: refreshTokenExpiry.value,
    })
  }

  return {
    accessToken,
    isAuthenticated,
    accessTokenExpiry,
    sessionExpiry: refreshTokenExpiry,
    isExpired,
    hasRefreshTokenCookie,
    isLongLived,
    clearSessionData,
    setSessionData,
  }
}
