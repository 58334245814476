// useModal.js
import { useEventBus } from "@vueuse/core"
import { ref } from "vue"

export default function useAuthErrorDialog() {
  const bus = useEventBus("authErrorDialog")
  const isErrorDialogShown = ref(false)

  bus.on(() => {
    isErrorDialogShown.value = true
  })
  const showDialog = () => {
    bus.emit()
  }
  return { isErrorDialogShown, showDialog }
}
