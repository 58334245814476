<script lang="ts" setup>
  import { cn } from "@/lib/utils"
  import {
    CalendarGrid,
    useForwardProps,
    type CalendarGridProps,
  } from "radix-vue"
  import { computed, type HTMLAttributes } from "vue"

  const props = defineProps<
    CalendarGridProps & { class?: HTMLAttributes["class"] }
  >()

  const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props

    return delegated
  })

  const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
  <CalendarGrid
    :class="cn('w-full border-collapse', props.class)"
    v-bind="forwardedProps"
  >
    <slot />
  </CalendarGrid>
</template>
