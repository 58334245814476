<script setup lang="ts">
  import { isAbsoluteUrl } from "@/lib/isAbsoluteUrl"

  const props = defineProps<{
    to: string
    target?: string
  }>()
</script>

<template>
  <component
    :is="to === 'javascript:;' || isAbsoluteUrl(props.to) ? 'a' : 'router-link'"
    :to="to"
    :target="target"
    :href="to"
  >
    <slot></slot>
  </component>
</template>
