<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import type { HTMLAttributes } from "vue"

  import { badgeVariants, type BadgeVariants } from "."

  const props = defineProps<{
    variant?: BadgeVariants["variant"]
    class?: HTMLAttributes["class"]
    dataTestid?: string
  }>()
</script>

<template>
  <div
    :class="cn(badgeVariants({ variant }), props.class)"
    :data-testid="dataTestid"
  >
    <slot />
  </div>
</template>
