import routesDefinition from "@/plugins/router/routes.ts"
import { createRouter, createWebHistory } from "vue-router"

import { beforeMetaTitle } from "./metaTitle"
import { processRedirectParameter } from "./processRedirectParameter"
import { beforeEachGuard } from "./routerGuards"

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routesDefinition,
  scrollBehavior() {
    return { top: 0 }
  },
})

router.beforeEach(processRedirectParameter)
router.beforeEach(beforeEachGuard)
router.beforeEach(beforeMetaTitle)

export default router
