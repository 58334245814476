<script lang="ts" setup>
  import { cn } from "@/lib/utils"
  import {
    CalendarCellTrigger,
    useForwardProps,
    type CalendarCellTriggerProps,
  } from "radix-vue"
  import { computed, type HTMLAttributes } from "vue"
  import { buttonVariants } from "@/components/ui/button"

  const props = defineProps<
    CalendarCellTriggerProps & { class?: HTMLAttributes["class"] }
  >()

  const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props

    return delegated
  })

  const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
  <CalendarCellTrigger
    :class="
      cn(
        buttonVariants({ variant: 'text', size: 'lg' }),
        'w-10 p-0 font-sans font-normal max-sm:text-xs',
        'active:bg-secondary-200',
        '[&[data-today]:not([data-selected])]:bg-primary-100',
        // Selected
        'data-[selected]:bg-secondary-700 data-[selected]:text-secondary-100 data-[selected]:opacity-100 data-[selected]:hover:bg-secondary-700 data-[selected]:focus:bg-secondary-700',
        // Disabled
        'data-[disabled]:text-secondary-400 data-[disabled]:hover:bg-transparent',
        // Unavailable
        'data-[unavailable]:text-destructive-foreground data-[unavailable]:line-through',
        // Outside months
        'data-[outside-view]:text-secondary-400 [&[data-outside-view][data-selected]]:text-secondary-100',
        props.class,
      )
    "
    v-bind="forwardedProps"
  >
    <slot />
  </CalendarCellTrigger>
</template>
