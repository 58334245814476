export const PROJECTS = {
  Website: "fe-website",
  Whd: "fe-whd",
  Smbp: "fe-smbp",
  Solar: "fe-solar",
}

export const PROJECT_BASE_URLS = {
  [PROJECTS.Website]: "/",
  [PROJECTS.Whd]: "/warm-home-discount",
  [PROJECTS.Smbp]: "/smart-meter-booking",
  [PROJECTS.Solar]: "/solar/quote",
}
