import BaseLayout from "@/layouts/BaseLayout.vue"
import EmptyLayout from "@/layouts/EmptyLayout.vue"
import SidebarLayout from "@/layouts/SidebarLayout.vue"

export const Layouts = {
  DEFAULT: "DEFAULT",
  EMPTY: "EMPTY",
  SIDEBAR: "SIDEBAR",
}

export const LayoutComponent = {
  [Layouts.DEFAULT]: BaseLayout,
  [Layouts.EMPTY]: EmptyLayout,
  [Layouts.SIDEBAR]: SidebarLayout,
}
