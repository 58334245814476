import { AlertTemplate } from "."

const VITE_PUBLIC_WEBSITE_URL = import.meta.env.VITE_PUBLIC_WEBSITE_URL

export const alertTemplates: AlertTemplate[] = [
  {
    name: "accountClosed",
    title: "Account closed",
    description: "Your account is closed.",
    dismissible: false,
  },
  {
    name: "fixedTariffRenewal",
    title: "Time to renew your tariff",
    description:
      "Your tariff is ending soon. Renew now and keep your green energy with a fixed tariff.",
    action: {
      label: "Renew",
      linkTo: `${VITE_PUBLIC_WEBSITE_URL}/myaccount/renew`,
    },
    dismissible: false,
  },
  {
    name: "missingSmartRead",
    title: "We need a meter reading",
    description:
      "Your smart meter seems to be having trouble sending us readings. To keep things accurate on your account and ensure we're billing you correctly, please submit meter readings whenever you can - it only takes a minute.",
    action: {
      label: "Submit Readings",
      linkTo: `${VITE_PUBLIC_WEBSITE_URL}/myaccount/readings`,
    },
    dismissible: false,
  },
  {
    name: "smartMeterInstallationBooked",
    title: "Your Smart Meter appointment details",
    description: "Your smart meter installation booking is confirmed.",
    action: {
      label: "Manage Booking",
      linkTo: `${VITE_PUBLIC_WEBSITE_URL}/smart-meter-booking/appointment-details`,
    },
    dismissible: true,
  },
  {
    name: "noPriorDirectDebit",
    title: "Set up your Direct Debit",
    description:
      "We have never received a Direct Debit payment from you for your energy usage. Please make sure you have a Direct Debit set up to pay for the energy you use.",
    action: {
      linkTo: `${VITE_PUBLIC_WEBSITE_URL}/myaccount/payments`,
      label: "Set Up",
    },
    dismissible: false,
  },
  {
    name: "failingDirectDebitPayment",
    title: "Your Direct Debit has failed",
    description:
      "We've been unable to collect a Direct Debit payment. Please ensure there is enough money in your account to cover your energy bill, or update your Direct Debit details.",
    action: {
      label: "Set Up",
      linkTo: `${VITE_PUBLIC_WEBSITE_URL}/myaccount/payments`,
    },
    dismissible: false,
  },
  {
    name: "failedDirectDebitSetup",
    title: "Your Direct Debit has failed to set up",
    description:
      "Welcome to So Energy! Unfortunately, we've been unable to set up your Direct Debit. Please set up a Direct Debit to pay for your energy usage.",
    action: {
      label: "Set Up",
      linkTo: `${VITE_PUBLIC_WEBSITE_URL}/myaccount/payments`,
    },
    dismissible: false,
  },
  {
    name: "missingDirectDebitOnNonDDTariff",
    title: "You could save by setting up your Direct Debit",
    description:
      "With a Direct Debit you'll receive a discount on your rates. The average household will save £137 a year with the discounted rates. Setting up a Direct Debit is the quickest and easiest way to pay for the energy you use, and helps ensure you never fall behind on bills.",
    action: {
      label: "Set Up",
      linkTo: `${VITE_PUBLIC_WEBSITE_URL}/myaccount/payments`,
    },
    dismissible: false,
  },
  {
    name: "cancelledDirectDebit",
    title: "We can see your Direct Debit has been cancelled",
    description:
      "Having a Direct Debit is the best way to keep in control of your energy account balance. Making the right regular payments will ensure you stay out of debt and if you're in credit, we'll need that Direct Debit to issue you a refund.",
    action: {
      label: "Set Up",
      linkTo: `${VITE_PUBLIC_WEBSITE_URL}/myaccount/payments`,
    },
    dismissible: false,
  },
  {
    name: "lossInitiatedDirectDebitWarning",
    title: "Please don't cancel your Direct Debit",
    description:
      "It's important that you don't cancel your Direct Debit with us until your switch away is complete, there may be credit or debit on the account that we'll have to refund or take from your bank account.",
    dismissible: false,
  },
  {
    name: "partialLossInitiated",
    title: "Did you request a switch away?",
    description:
      "One of your fuels is switching away from So Energy. Your account will remain open for all other meterpoints. If you do not wish for any meters to switch away please contact us, or your new supplier to cancel this switch.",
    action: {
      label: "Contact Us",
      linkTo: `${VITE_PUBLIC_WEBSITE_URL}/myaccount/contact-us`,
    },
    dismissible: false,
  },
  {
    name: "cancelledDirectDebitDuringSwitchAway",
    title: "Cancelled Direct Debit during switching away",
    description:
      "You're switching away and cancelled your DD - please enter your DD details so we can settle your final bill.",
    action: {
      label: "Set Up",
      linkTo: `${VITE_PUBLIC_WEBSITE_URL}/myaccount/payments`,
    },
    dismissible: false,
  },
  {
    name: "missingManualReadNotice",
    title: "Please submit your meter read",
    description:
      "We need a read! To keep things accurate on your account and ensure we're billing you correctly, please submit meter readings whenever you can - it only takes a minute.",
    action: {
      label: "Submit Readings",
      linkTo: `${VITE_PUBLIC_WEBSITE_URL}/myaccount/readings`,
    },
    dismissible: false,
  },
  {
    name: "missingOMRPostSupply",
    title: "It's time for your opening read",
    description: "Welcome to So! Please submit your opening read.",
    action: {
      label: "Submit Readings",
      linkTo: `${VITE_PUBLIC_WEBSITE_URL}/myaccount/readings`,
    },
    dismissible: false,
  },
  {
    name: "missingElectricityOMR",
    title: "It's time for your opening electricity read",
    description:
      "You've enrolled your electricity with us! We need an opening meter read to start billing you.",
    action: {
      label: "Submit Reading",
      linkTo: `${VITE_PUBLIC_WEBSITE_URL}/myaccount/readings`,
    },
    dismissible: false,
  },
  {
    name: "missingGasOMR",
    title: "It's time for your opening gas read",
    description:
      "You've enrolled your gas with us! We need an opening meter read to start billing you.",
    action: {
      label: "Submit Reading",
      linkTo: `${VITE_PUBLIC_WEBSITE_URL}/myaccount/readings`,
    },
    dismissible: false,
  },
  {
    name: "onboardingAdditionalMeterpoint",
    title: "Your switch has nearly completed",
    description:
      "The additional meterpoint you requested to switch is in progress.",
    dismissible: false,
  },
  {
    name: "recentlyOnboarded",
    title: "Your switch is complete!",
    description:
      "You're now on supply with So Energy! Your first payment will be taken within 10 days of your supply start date and then monthly thereafter.",
    dismissible: false,
  },
  {
    name: "enrolmentObjected",
    title: "Your previous supplier has objected to your switch.",
    description:
      "To resolve this you need to contact them and resolve any outstanding issues with the account. Alternatively, if you have an online account with them, you may be able to resolve it yourself.",
    dismissible: false,
  },
  {
    name: "enrolmentRejected",
    title: "Your switch to us has been rejected",
    description:
      "We ran into an issue with your switch. We'll try to fix this, but if we need anything from you we'll be in touch.",
    dismissible: false,
  },
  {
    name: "seasonalPaymentNotice",
    title: "Your payments are changing",
    description:
      "Summer payments are starting soon. From April to September, your bills will be coming down to reflect the fact that you'll be using less energy.",
    dismissible: true,
  },
  {
    name: "winterPaymentNotice",
    title: "Your payments are changing",
    description:
      "Winter payments are almost here. As your energy consumption will go up in winter, the amount you pay will also rise. You can pay the same amount every month throughout the year by switching to equal payments.",
    dismissible: true,
  },
  {
    name: "unbilledAccount",
    title: "There's a problem generating your bills",
    description:
      "We've noticed there's an issue with your next bill. Don't worry, we're working on the problem and will contact you with any information we have.",
    dismissible: false,
  },
  {
    name: "onboardingSmartIssue1",
    title: "There has been an issue reading your smart meter",
    description:
      "To make sure that your bills are accurate, please enter a manual reading from your meter.",
    action: {
      label: "Submit Readings",
      linkTo: `${VITE_PUBLIC_WEBSITE_URL}/myaccount/readings`,
    },
    dismissible: false,
  },
  {
    name: "onboardingSmartIssue2",
    title: "There has been an issue reading your smart meter",
    description:
      "We're still trying to connect to your smart meter. We're working on it and will be in touch if we need anything from you.",
    dismissible: false,
  },
  {
    name: "onboardingSmartSuccess",
    title: "We are taking smart meter readings",
    description:
      "If you'd like to check the readings we are taking, you can view your readings in your account. If you suspect something might not be quite right, please get in touch.",
    action: {
      label: "View Readings",
      linkTo: `${VITE_PUBLIC_WEBSITE_URL}/myaccount/usage`,
    },
    dismissible: false,
  },
  {
    name: "switchCancelled",
    title: "Switch Cancelled",
    description: "Your supply will remain with your current supplier.",
    dismissible: false,
  },
]
