<script lang="ts" setup>
  import { useGetCustomer } from "@/api/customer/customer.ts"
  import { authSession } from "@/authentication/authenticationService.ts"
  import { useLocalStorage } from "@vueuse/core"
  import { ref, watch, watchEffect } from "vue"
  import { Button } from "@/components/ui/button"
  import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogIllustration,
    DialogTitle,
  } from "@/components/ui/dialog"

  import soBucket from "@/assets/illustrations/illustration-so-bucket.svg"

  const { isAuthenticated } = authSession
  const customer = useGetCustomer({ query: { enabled: isAuthenticated } })
  const isOpen = ref(false)
  const onboardingDialogCustomersList = useLocalStorage<(number | undefined)[]>(
    "onboardingDialogCustomersList",
    [],
  )

  watchEffect(() => {
    if (
      customer.data.value?.customerID &&
      !onboardingDialogCustomersList.value.includes(
        customer.data.value?.customerID,
      )
    ) {
      isOpen.value = true
    }
  })

  watch(isOpen, (newValue) => {
    if (!newValue) {
      onboardingDialogCustomersList.value.push(customer.data.value?.customerID)
    }
  })
</script>
<template>
  <Dialog
    v-if="isAuthenticated"
    v-model:open="isOpen"
    data-testid="onboarding-dialog"
  >
    <DialogContent>
      <template #illustration>
        <DialogIllustration :src="soBucket" alt="SoBucket" />
      </template>
      <DialogHeader>
        <DialogTitle>A fresh new look for your account!</DialogTitle>
      </DialogHeader>
      <div>
        <p>
          Experience your account with a fresh new look! We've completely
          restructured the account interface to help you find what you need
          faster and easier. We're also committed to continuous improvements to
          make your experience even better.
        </p>
        <p>Try it out and let us know what you think!</p>
      </div>
      <DialogFooter>
        <DialogClose as-child>
          <Button>Got It!</Button>
        </DialogClose>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>
