<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import {
    ComboboxRoot,
    useForwardPropsEmits,
    type ComboboxRootEmits,
    type ComboboxRootProps,
  } from "radix-vue"
  import { computed, type HTMLAttributes } from "vue"

  const props = withDefaults(
    defineProps<ComboboxRootProps & { class?: HTMLAttributes["class"] }>(),
    {
      modelValue: "",
    },
  )

  const emits = defineEmits<ComboboxRootEmits>()

  const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props

    return delegated
  })

  const forwarded = useForwardPropsEmits(delegatedProps, emits)
</script>

<template>
  <ComboboxRoot
    v-bind="forwarded"
    :class="
      cn(
        'flex h-full w-full flex-col rounded-md text-popover-foreground',
        props.class,
      )
    "
  >
    <template #default="{ open }">
      <slot :combobox-open="open" />
    </template>
  </ComboboxRoot>
</template>
