<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import {
    ComboboxContent,
    useForwardPropsEmits,
    type ComboboxContentEmits,
    type ComboboxContentProps,
  } from "radix-vue"
  import { computed, type HTMLAttributes } from "vue"

  const props = defineProps<
    ComboboxContentProps & { class?: HTMLAttributes["class"] }
  >()
  const emits = defineEmits<ComboboxContentEmits>()

  const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props

    return delegated
  })

  const forwarded = useForwardPropsEmits(delegatedProps, emits)
</script>

<template>
  <ComboboxContent
    v-bind="forwarded"
    :class="
      cn(
        'max-h-[300px] overflow-y-auto overflow-x-hidden p-4',
        'rounded-sm border bg-secondary-100',
        'dark:border-secondary-400 dark:bg-secondary-500 ',
        props.class,
      )
    "
  >
    <div role="presentation" class="overflow-x-scroll">
      <slot />
    </div>
  </ComboboxContent>
</template>
