<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import {
    ComboboxInput,
    ComboboxTrigger,
    useForwardProps,
    type ComboboxInputProps,
  } from "radix-vue"
  import { computed, type HTMLAttributes } from "vue"
  import { Icon } from "@/components/ui/icons"

  defineOptions({
    inheritAttrs: false,
  })

  const props = defineProps<
    ComboboxInputProps & {
      class?: HTMLAttributes["class"]
      error?: string | undefined
      disabled?: boolean
      ariaLabel?: string
    }
  >()

  const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props

    return delegated
  })

  const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
  <div class="flex items-center" cmdk-input-wrapper>
    <ComboboxTrigger class="w-full" :disabled="disabled">
      <div class="group relative">
        <ComboboxInput
          v-bind="{ ...forwardedProps, ...$attrs }"
          auto-focus
          data-testid="combobox-input"
          :aria-label="ariaLabel"
          :class="
            cn(
              'relative flex h-12 w-full justify-start rounded-sm border bg-secondary-100 px-4 py-3 pr-12 ring-primary-400 sm:h-13',
              'body-text font-sans',
              'group-hover:border-secondary-400 group-hover:focus-visible:border-primary-400',
              'focus-visible:border-primary-400 focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-offset-0',
              'disabled:cursor-not-allowed disabled:border-secondary-500 disabled:bg-secondary-300 disabled:text-secondary-400',
              error && [
                'border-red-400 ring-red-400 focus-visible:border-red-400 group-hover:border-red-400 group-hover:focus-visible:border-red-400',
              ],
              props.class,
            )
          "
        />
        <Icon
          size="lg"
          name="ChevronDown"
          aria-label="Show options"
          :class="
            cn([
              'absolute top-1/2 -translate-y-1/2  text-secondary-700 focus-visible:outline-offset-2',
              'focus-visible:outline-primary-400',
              'dark:focus-visible:outline-primary-300',
              'right-4',
            ])
          "
        />
      </div>
    </ComboboxTrigger>
  </div>
</template>
