<script lang="ts" setup>
  import {
    CalendarNext,
    useForwardProps,
    type CalendarNextProps,
  } from "radix-vue"
  import { computed, type HTMLAttributes } from "vue"
  import { IconButton } from "@/components/ui/icon-button"

  const props = defineProps<
    CalendarNextProps & { class?: HTMLAttributes["class"] }
  >()

  const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props

    return delegated
  })

  const forwardedProps = useForwardProps(delegatedProps)
</script>

<template>
  <CalendarNext v-bind="forwardedProps" as-child>
    <slot>
      <IconButton
        variant="text"
        icon="ArrowRight"
        label="Close alert"
        size="sm"
      />
    </slot>
  </CalendarNext>
</template>
