import { useGetCustomer } from "@/api/customer/customer.ts"
import { authSession } from "@/authentication/authenticationService"
import { ExternalRoute } from "@/constants/externalRoute"
import { computed, watch } from "vue"

export function useGetAccountId() {
  const { isAuthenticated } = authSession

  const customer = useGetCustomer({
    query: {
      enabled: isAuthenticated,
    },
  })

  watch(
    () => customer.error.value,
    (error) => {
      if (!error) return

      if (import.meta.env.DEV) {
        console.warn(
          `Skipping redirect to login error page in dev mode.\n` +
            `Would redirect to: ${ExternalRoute.loginError}`,
        )
        return
      }

      window.location.assign(ExternalRoute.loginError)
    },
    { immediate: true },
  )

  const accountId = computed(() => {
    return customer.data?.value?.accountIDLastVisited ?? 0
  })

  return {
    accountId,
    ...customer,
  }
}
