<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import { HTMLAttributes } from "vue"

  const props = defineProps<{
    class?: HTMLAttributes["class"]
  }>()
</script>

<template>
  <div :class="cn('flex flex-col', props.class)" data-testid="dialog-header">
    <slot />
  </div>
</template>
