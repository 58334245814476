import { getMyAccountUrl, getPublicWebsiteUrl } from "@/lib/websiteUrls"

import { Route } from "./route"

const PUBLIC_WEBSITE_URL = getPublicWebsiteUrl()
const LEGACY_MYACCOUNT_URL = getMyAccountUrl()

const helpRoutes = {
  getSupport: `${PUBLIC_WEBSITE_URL}support/home`,
  energySaving: `${PUBLIC_WEBSITE_URL}energy/saving`,
  unhappy: `${PUBLIC_WEBSITE_URL}help/unhappy`,
  emergency: `${PUBLIC_WEBSITE_URL}help/emergency`,
  priceCap: `${PUBLIC_WEBSITE_URL}help/priceCap`,
  troubleshooting: `${PUBLIC_WEBSITE_URL}troubleshooting-ihd`,
} as const

const productRoutes = {
  solar: `${PUBLIC_WEBSITE_URL}solar`,
  homeCover: `${PUBLIC_WEBSITE_URL}heating/so-home-cover`,
  heatPumps: `${PUBLIC_WEBSITE_URL}heating/so-heat-pumps`,
  smartMeters: `${PUBLIC_WEBSITE_URL}energy/smart-meters`,
} as const

const aboutUsRouters = {
  quote: `${PUBLIC_WEBSITE_URL}quote`,
  aboutUs: `${PUBLIC_WEBSITE_URL}about-us`,
  reviews: `${PUBLIC_WEBSITE_URL}about-us/reviews`,
  news: `${PUBLIC_WEBSITE_URL}news`,
  careers: `https://careers.so.energy`,
  ourEnergy: `${PUBLIC_WEBSITE_URL}our-energy`,
} as const

export const ExternalRoute = {
  // LEGACY WEBSITE URLS
  tariff: `${LEGACY_MYACCOUNT_URL}tariff`,
  usage: `${LEGACY_MYACCOUNT_URL}usage`,
  billsAndStatements: `${LEGACY_MYACCOUNT_URL}balance`,
  referAFriend: `${LEGACY_MYACCOUNT_URL}refer`,
  payments: `${LEGACY_MYACCOUNT_URL}payments`,
  makePayment: `${LEGACY_MYACCOUNT_URL}payments/new`,
  meterReadings: `${LEGACY_MYACCOUNT_URL}readings`,
  personalDetails: `${LEGACY_MYACCOUNT_URL}personal-details`,
  fixedRenewal: `${LEGACY_MYACCOUNT_URL}renew?fixed-contract`,
  renewal: `${LEGACY_MYACCOUNT_URL}renew`,
  loginError: `${LEGACY_MYACCOUNT_URL}login-error`,

  websiteHome: `${PUBLIC_WEBSITE_URL}`,
  movingInTypeform: `${PUBLIC_WEBSITE_URL}/moving-in`,
  referFriend: `${PUBLIC_WEBSITE_URL}refer-a-friend`,
  contact: `${PUBLIC_WEBSITE_URL}contact`,
  faq: `${PUBLIC_WEBSITE_URL}faq`,
  help: `${PUBLIC_WEBSITE_URL}help`,
  directDebit: `${PUBLIC_WEBSITE_URL}direct-debit`,
  movingHome: `${PUBLIC_WEBSITE_URL}help/moving`,
  getAQuote: `${PUBLIC_WEBSITE_URL}energy/quote`,
  warmHomeDiscount: `${PUBLIC_WEBSITE_URL}warm-home-discount`,
  smartMeterBooking: `${PUBLIC_WEBSITE_URL}smart-meter-booking`,
  ...aboutUsRouters,
  ...productRoutes,
  ...helpRoutes,
} as const

export type ExternalRoute = (typeof ExternalRoute)[keyof typeof ExternalRoute]

export function isExternalRoute(
  route: Route | ExternalRoute,
): route is ExternalRoute {
  return !!Object.values(ExternalRoute).find((item) => item === route)
}
