/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Portal API Gateway
 * OpenAPI spec version: 2.0.0
 */
import {
  useQuery,
  type QueryFunction,
  type QueryKey,
  type UseQueryOptions,
  type UseQueryReturnType,
} from "@tanstack/vue-query"
import { unref, type MaybeRef } from "vue"

import { customInstance, type ErrorType } from ".././client"
import type { AddressMeterPointDetails, Error, Postcode } from ".././model"
import { useQueryOptions } from ".././queryOptions"

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

/**
 * return a list of addresses for a given postcode with an indication for those with billing accounts
 * @summary return a list of addresses for a given postcode with an indication for those with billing accounts
 */
export const addressesByPostcode = (
  postcode: MaybeRef<Postcode>,
  options?: SecondParameter<typeof customInstance>,
  signal?: AbortSignal,
) => {
  postcode = unref(postcode)

  return customInstance<AddressMeterPointDetails[]>(
    { url: `/api/v1/addresses/${postcode}`, method: "GET", signal },
    options,
  )
}

export const getAddressesByPostcodeQueryKey = (
  postcode: MaybeRef<Postcode>,
) => {
  return ["api", "v1", "addresses", postcode] as const
}

export const useAddressesByPostcodeQueryOptions = <
  TData = Awaited<ReturnType<typeof addressesByPostcode>>,
  TError = ErrorType<Error>,
>(
  postcode: MaybeRef<Postcode>,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof addressesByPostcode>>,
        TError,
        TData
      >
    >
    request?: SecondParameter<typeof customInstance>
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getAddressesByPostcodeQueryKey(postcode)

  const queryFn: QueryFunction<
    Awaited<ReturnType<typeof addressesByPostcode>>
  > = ({ signal }) => addressesByPostcode(postcode, requestOptions, signal)

  const customOptions = useQueryOptions(
    { ...queryOptions, queryKey, queryFn },
    { postcode },
  )

  return customOptions as UseQueryOptions<
    Awaited<ReturnType<typeof addressesByPostcode>>,
    TError,
    TData
  >
}

export type AddressesByPostcodeQueryResult = NonNullable<
  Awaited<ReturnType<typeof addressesByPostcode>>
>
export type AddressesByPostcodeQueryError = ErrorType<Error>

/**
 * @summary return a list of addresses for a given postcode with an indication for those with billing accounts
 */
export const useAddressesByPostcode = <
  TData = Awaited<ReturnType<typeof addressesByPostcode>>,
  TError = ErrorType<Error>,
>(
  postcode: MaybeRef<Postcode>,
  options?: {
    query?: Partial<
      UseQueryOptions<
        Awaited<ReturnType<typeof addressesByPostcode>>,
        TError,
        TData
      >
    >
    request?: SecondParameter<typeof customInstance>
  },
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useAddressesByPostcodeQueryOptions(postcode, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & {
    queryKey: QueryKey
  }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}
