<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import { HTMLAttributes } from "vue"

  const props = defineProps<{
    class?: HTMLAttributes["class"]
  }>()
</script>

<template>
  <h5
    :class="cn('body-text-strong mb-1', props.class)"
    data-testid="alert-title"
  >
    <slot />
  </h5>
</template>
