<script setup lang="ts">
  import { DefaultDateFormat } from "@/constants/date"
  import { useCotMoveIn } from "@/lib/useCotMoveIn"
  import { useStepWizard } from "@/lib/useStepWizard"
  import { toTypedSchema } from "@vee-validate/zod"
  import dayjs from "dayjs"
  import customParseFormat from "dayjs/plugin/customParseFormat"
  import { useForm } from "vee-validate"
  import { computed } from "vue"
  import { z } from "zod"
  import { Alert, AlertDescription } from "@/components/ui/alert"
  import { Button } from "@/components/ui/button"
  import { DatePicker } from "@/components/ui/inputs/date-picker"
  import { Link } from "@/components/ui/link"
  import { StepWizardProgressCard } from "@/components/ui/step-wizard"

  dayjs.extend(customParseFormat)

  const { goNext } = useStepWizard()
  const { state, updateDetails } = useCotMoveIn()
  const candidateFdor = computed(() => state.value.moveInAddress?.candidateFDOR)
  const candidateFdorMessage = computed(() =>
    dayjs(state.value.moveInAddress?.candidateFDOR, "YYYY-MM-DD")
      .add(1, "day")
      .format(DefaultDateFormat),
  )
  const showFdorAlert = computed(
    () =>
      !state.value.fdor ||
      !candidateFdor.value ||
      dayjs(state.value.fdor, DefaultDateFormat).isAfter(candidateFdor.value),
  )
  const formSchema = toTypedSchema(
    z.object({
      fdor: z
        .string({
          required_error: "Please enter a valid date",
          invalid_type_error: "Please enter a valid date",
        })
        .min(1, "Please enter a valid date")
        .refine(
          (fdor) =>
            !candidateFdor.value ||
            dayjs(fdor, DefaultDateFormat).isAfter(candidateFdor.value),
          {
            message:
              "Please select a date after the previous occupier's move-out date.",
          },
        ),
    }),
  )

  const { handleSubmit, validate } = useForm({
    validationSchema: formSchema,
  })

  const onChange = () => {
    validate()
  }

  const submit = handleSubmit(async (data) => {
    updateDetails({ fdor: data.fdor })
    goNext()
  })
</script>

<template>
  <StepWizardProgressCard title="First date of responsibility">
    <p class="mb-4 sm:mb-6">
      This will be the first date of your tenancy or the sale date. If you don't
      know the date yet, no worries, just come back to this form when you do.
      Submitting the form with the correct date will ensure you are correctly
      billed.
    </p>
    <form>
      <DatePicker
        v-model="state.fdor"
        name="fdor"
        label="Date"
        class="max-w-[348px]"
        :show-label="false"
        @update:model-value="onChange"
      />
    </form>
    <Alert v-if="!showFdorAlert" variant="info" class="mt-4">
      <AlertDescription>
        It looks like someone is still in the property. Please select a date
        from {{ candidateFdorMessage }}. If you believe your date is correct,
        please contact us on
        <Link class="link text-primary-600" to="tel:0330 111 5050"
          >0330 111 5050</Link
        >.
      </AlertDescription>
    </Alert>
    <template #footer>
      <Button variant="tonal" size="md" @click="submit">Next</Button>
    </template>
  </StepWizardProgressCard>
</template>
