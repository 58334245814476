<script setup lang="ts">
  import { cn } from "@/lib/utils.ts"
  import {
    DialogTitle,
    useForwardProps,
    type DialogTitleProps,
  } from "radix-vue"
  import { computed, inject, type HTMLAttributes } from "vue"

  const props = defineProps<
    DialogTitleProps & { class?: HTMLAttributes["class"] }
  >()

  const delegatedProps = computed(() => {
    const { class: _, ...delegated } = props

    return delegated
  })

  const forwardedProps = useForwardProps(delegatedProps)
  const hasIllustration = inject("hasIllustration")
</script>

<template>
  <DialogTitle
    v-bind="forwardedProps"
    :class="
      cn(
        'h3 ',
        hasIllustration ? '-mt-4 py-3 sm:py-4' : 'pb-3 pt-4 sm:pb-4 sm:pt-6',
        props.class,
      )
    "
    data-testid="dialog-title"
  >
    <slot />
  </DialogTitle>
</template>
