import { useScriptTag } from "@vueuse/core"

export const initWebchat = () => {
  const FRESHDESK_WIDGET_SCRIPT_URL = import.meta.env
    .VITE_FRESHDESK_WIDGET_SCRIPT_URL

  if (!FRESHDESK_WIDGET_SCRIPT_URL) {
    throw new Error("Missing Chatbot Script URL")
  }
  useScriptTag(FRESHDESK_WIDGET_SCRIPT_URL)
}

export const openWebchat = () => {
  if (typeof window.fcWidget?.open !== "function") {
    throw new Error("Webchat not initialized")
  }
  window.fcWidget.open()
}
