/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Portal API Gateway
 * OpenAPI spec version: 2.0.0
 */

/**
 * Current state for direct debit
 */
export type DirectDebitState =
  (typeof DirectDebitState)[keyof typeof DirectDebitState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const DirectDebitState = {
  noPriorDirectDebit: "noPriorDirectDebit",
  pendingActivation: "pendingActivation",
  failedSetup: "failedSetup",
  activeFixed: "activeFixed",
  activeVariable: "activeVariable",
  failedPayment: "failedPayment",
  cancelled: "cancelled",
  unknown: "unknown",
} as const
