<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import { defineProps, provide, ref } from "vue"

  interface RadioCardGroupProps {
    modelValue?: string | number
    ariaLabelledby: string
    ariaLabel?: string
    isHorizontal?: boolean
  }

  const props = withDefaults(defineProps<RadioCardGroupProps>(), {
    isHorizontal: false,
  })

  const emit = defineEmits(["update:modelValue"])

  const selectedValue = ref(props.modelValue)

  provide("selectedValue", selectedValue)
  provide("updateSelectedValue", (value: string | number) => {
    selectedValue.value = value
    emit("update:modelValue", value)
  })
</script>

<template>
  <div
    :class="cn('flex gap-4', isHorizontal ? 'flex-row' : 'flex-col')"
    :aria-label="ariaLabel"
    :aria-labelledby="ariaLabelledby"
    role="radiogroup"
  >
    <slot />
  </div>
</template>
