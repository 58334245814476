<script setup lang="ts">
  import { Button } from "@/components/ui/button"
  import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
  } from "@/components/ui/dialog"

  const emit = defineEmits<(e: "dialogClosed") => void>()
</script>

<template>
  <Dialog :open="true" @update:open="emit('dialogClosed')">
    <DialogContent>
      <DialogHeader>
        <DialogTitle>Login error</DialogTitle>
      </DialogHeader>
      <p>
        It looks like there's a problem with logging out! Please try again or
        clear your browser cookies.
      </p>
      <DialogFooter>
        <DialogClose as-child>
          <Button variant="outline">Close</Button>
        </DialogClose>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>
