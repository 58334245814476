<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import type { HTMLAttributes } from "vue"

  const props = defineProps<{
    class?: HTMLAttributes["class"]
  }>()
</script>

<template>
  <div
    :class="
      cn(
        'flex flex-col rounded border border-secondary-300 bg-secondary-100 p-6 dark:border-secondary-500 dark:bg-secondary-600 dark:text-secondary-100',
        props.class,
      )
    "
    data-testid="card"
  >
    <slot />
  </div>
</template>
