/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Portal API Gateway
 * OpenAPI spec version: 2.0.0
 */

/**
 * Current state for agreements
 */
export type AgreementState =
  (typeof AgreementState)[keyof typeof AgreementState]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AgreementState = {
  preSsdCoolingOffPeriod: "preSsdCoolingOffPeriod",
  postSsdCoolingOffPeriod: "postSsdCoolingOffPeriod",
  variableTariff: "variableTariff",
  earlyRenewalPeriod: "earlyRenewalPeriod",
  standardRenewalPeriod: "standardRenewalPeriod",
  fixedTariffRenewalAgreedCoolingOffPeriod:
    "fixedTariffRenewalAgreedCoolingOffPeriod",
  fixedTariffCoolingOffPeriod: "fixedTariffCoolingOffPeriod",
  fixedTariffRenewalAgreedAwaitingStart:
    "fixedTariffRenewalAgreedAwaitingStart",
  fixedTariffNoActionRequired: "fixedTariffNoActionRequired",
  noActiveAgreement: "noActiveAgreement",
  unknown: "unknown",
} as const
