/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * Portal API Gateway
 * OpenAPI spec version: 2.0.0
 */

/**
 * Severity of the alert
 */
export type AlertSeverity = (typeof AlertSeverity)[keyof typeof AlertSeverity]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AlertSeverity = {
  info: "info",
  warning: "warning",
  critical: "critical",
  success: "success",
} as const
