import { canInitializeDatadog } from "@/plugins/datadog/datadogHelper.ts"
import { datadogRum } from "@datadog/browser-rum"

export default {
  report(error: unknown, metadata = {}) {
    if (canInitializeDatadog()) {
      datadogRum.addError(error, metadata)
    }
  },
}
