export const Route = {
  home: `/`,
  profile: `/profile`,
  login: `/login`,
  moveIn: `/move-in`,
  cotMoveInPersonalDetails: `personal-details`,
  cotMoveInFindAddress: `move-in-address`,
  cotMoveInFdor: "fdor",
  cotMoveInReview: "review",
  cotMoveInComplete: "/move-in-complete",
  maintenance: `/maintenance`,
  passwordReset: `/password-reset`,
  passwordChange: `/password-change`,
  activateAccount: `/activate-account`,
  setPassword: `/set-password`,
  readings: "/readings",
  tariff: "/tariff",
  help: "/help",
  payments: "/payments",
  directDebit: "/direct-debit",
  notFound: `/:pathMatch(.*)*`,
} as const

export type Route = (typeof Route)[keyof typeof Route]
