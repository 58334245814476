import {
  authSession,
  ensureFreshTokenIfAuthenticated,
} from "@/authentication/authenticationService"
import { nexusHomePageEnabled } from "@/lib/nexusHomePageEnabled.ts"
import { getMyAccountUrl } from "@/lib/websiteUrls"
import { unref } from "vue"
import { RouteLocationNormalizedGeneric } from "vue-router"

export const beforeEachGuard = async function (
  to: RouteLocationNormalizedGeneric,
) {
  const isNexusHomePageEnabled = nexusHomePageEnabled()

  const requiresAnon = to.matched.some((record) => record.meta.requiresAnon)
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)

  const queryString = window.location.search
  if (requiresAnon && authSession.hasRefreshTokenCookie()) {
    if (isNexusHomePageEnabled) return "/"

    const legacyMyAccountUrl = getMyAccountUrl()
    window.location.assign(`${legacyMyAccountUrl}${queryString}`)
    return false
  }

  if (requiresAuth) {
    await ensureFreshTokenIfAuthenticated()
    const isAuthenticated = unref(authSession.isAuthenticated)

    if (!isAuthenticated) {
      return `/login${queryString}`
    }
    if (isAuthenticated && to.query.redirect) {
      window.location.assign(
        `${decodeURIComponent(to.query.redirect as string)}`,
      )
      return false
    }
  }
}
