<script setup lang="ts">
  import { cn } from "@/lib/utils.ts"
  import { computed, defineProps, inject, ref, Ref } from "vue"

  interface Props {
    value: string | number
    name: string
    header?: string
    subheader?: string
    disabled?: boolean
    hasError?: boolean
  }

  const props = withDefaults(defineProps<Props>(), {
    disabled: false,
    hasError: false,
  })

  const selectedValue = inject<Ref<string | number | null>>(
    "selectedValue",
    ref(null),
  )
  const updateSelectedValue = inject<(value: string | number) => void>(
    "updateSelectedValue",
    () => {},
  )

  const isSelected = computed(() => selectedValue.value === props.value)

  const onSelect = () => {
    if (!props.disabled) {
      updateSelectedValue(props.value)
    }
  }
</script>

<template>
  <label
    :class="
      cn(
        'flex w-full flex-col rounded-md border p-4 focus-within:outline-none focus-within:ring-1 focus-within:ring-primary-400 hover:border-primary-400 sm:p-6',
        disabled
          ? 'cursor-not-allowed border-secondary-400 opacity-50 hover:border-secondary-400 '
          : 'cursor-pointer',
        !disabled && isSelected ? 'border-primary-400 bg-primary-500/10' : '',
        !disabled && hasError
          ? 'border-red-400 focus-within:ring-red-400 hover:border-red-400'
          : '',
      )
    "
    data-testid="radio-card-item"
  >
    <input
      class="sr-only"
      :value="value"
      :name="name"
      :checked="isSelected"
      :disabled="disabled"
      :aria-invalid="hasError"
      type="radio"
      @change="onSelect"
    />
    <div class="flex items-center justify-between">
      <h4>
        <slot name="header">{{ header }}</slot>
      </h4>
      <div
        :class="
          cn(
            'flex h-6 w-6 items-center justify-center rounded-full border-2 border-current',
            isSelected && 'border-primary-400',
            disabled && 'border-secondary-400',
          )
        "
      >
        <div
          v-if="isSelected"
          :class="
            cn(
              'h-3 w-3 rounded-full bg-primary-400',
              disabled && 'bg-secondary-400',
            )
          "
        ></div>
      </div>
    </div>
    <h5 class="body-text-strong mt-1">
      <slot name="subheader">{{ subheader }}</slot>
    </h5>
    <div class="mt-4 text-secondary-500">
      <slot />
    </div>
  </label>
</template>
