<script setup lang="ts">
  import { useGetAccount } from "@/api/account/account.ts"
  import { useGetAccountId } from "@/lib/useGetAccountId.ts"
  import Alert from "@/components/ui/alert/Alert.vue"

  import Alerts from "@/views/Alerts/Alerts.vue"
  import BalanceCard from "@/views/home/components/balance/BalanceCard.vue"
  import DirectDebitCard from "@/views/home/components/direct-debit/DirectDebitCard.vue"
  import MarketingBanners from "@/views/home/components/MarketingBanners.vue"

  const { accountId } = useGetAccountId()

  const account = useGetAccount(accountId)
</script>

<template>
  <Alert
    v-if="account?.data?.value?.isClosed"
    variant="error"
    data-testid="closed-account-alert"
    class="mt-4 md:mt-0"
  >
    Your account is closed
  </Alert>
  <div v-else class="grid grid-cols-1 gap-4 sm:gap-6">
    <MarketingBanners />
    <Alerts />
    <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-6">
      <BalanceCard />
      <DirectDebitCard />
    </div>
  </div>
</template>
