<script setup lang="ts">
  import { cn } from "@/lib/utils"
  import type { HTMLAttributes } from "vue"
  import {
    skeletonVariants,
    type SkeletonVariants,
  } from "@/components/ui/skeleton/index.ts"

  interface SkeletonProps {
    variant?: SkeletonVariants["variant"]
    class?: HTMLAttributes["class"]
  }

  const props = withDefaults(defineProps<SkeletonProps>(), {
    variant: "square",
  })
</script>

<template>
  <div :class="cn(skeletonVariants({ variant }), 'max-w-full', props.class)" />
</template>
